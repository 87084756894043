import { AppBar, Box, Button, Container, IconButton, Link, Toolbar } from '@mui/material'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { Menu as MenuIcon } from '../icons/menu'
import { Logo } from './logo'
import React from 'react'
import { getTenantLogoBackgroundColor } from 'src/lib/tenant'
import { LogoSwitcher } from './dashboard/dashboard-navbar'

interface MainNavbarProps {
  onOpenSidebar?: () => void
}

export const MainNavbar: FC<MainNavbarProps> = (props) => {
  const { onOpenSidebar } = props

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: getTenantLogoBackgroundColor(),
        borderBottomColor: 'divider',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        color: 'text.secondary',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ minHeight: 64 }}>
          <NextLink legacyBehavior href="/" passHref>
            <a style={{textDecoration:"none"}}>
              <LogoSwitcher />
            </a>
          </NextLink>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            onClick={onOpenSidebar}
            sx={{
              display: {
                md: 'none',
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

MainNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}
