import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

interface LogoProps {
  variant?: 'light' | 'primary'
}

export const Logo = styled((props: LogoProps) => {
  const { variant, ...other } = props

  const color = variant === 'light' ? '#C1C4D6' : '#5048E5'

  return (
    <svg width="42" height="42" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path d="M99.13,8.74c0,4.81-3.93,8.74-8.74,8.74-1.09,0-2.08-.22-3.06-.55l-16.59,81.42c-.22,.98-1.09,1.64-2.08,1.64H31.49c-.66,0-1.31-.33-1.75-.77-.44-.55-.55-1.2-.44-1.86L45.9,15.85H3.06c-1.2,0-2.19-.98-2.19-2.19s.98-2.19,2.19-2.19H82.08c-.33-.87-.44-1.75-.44-2.73,0-4.81,3.93-8.74,8.74-8.74s8.74,3.93,8.74,8.74Zm-4.37,0c0-2.4-1.97-4.37-4.37-4.37s-4.37,1.97-4.37,4.37,1.97,4.37,4.37,4.37,4.37-1.97,4.37-4.37Z"/>
    </svg>
  )
})``

Logo.defaultProps = {
  variant: 'primary',
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary']),
}
