import { getTenant } from "./tenant"


export function inquiryLink() {
  const tenantName = getTenant()
  if (tenantName == "classroomaid") { // classroom aidの場合
    return "https://forms.gle/62u5W4wX6Xza7r7F7"
  } else { // プリントハブの場合
    return "https://docs.google.com/forms/d/e/1FAIpQLSdBId9q4bic3ximTujbAMOXrdjTL36AS9AiE66K08vP8BuGYg/viewform?usp=sf_link"
  }
}
