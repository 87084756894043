import { Dialog } from '@mui/material'
import ImageList from '@mui/material/ImageList'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { PrintUser, PrintUserStatus } from 'src/types/print'
import { useIsWidthUp } from '../../hooks/use-is-width-up'
import YoutubePlayer from './youtube-player'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    width: 1800,
    height: 800,
  },
})

interface HelpMovieModalProps {
  onClose?: () => void
  open: boolean
}

const HelpMovieModal: React.FC<HelpMovieModalProps> = (props) => {
  const { onClose, open, ...other } = props
  const classes = useStyles()

  return (
    <Dialog maxWidth="xl" onClose={onClose} open={open} scroll={"body"} {...other}>
      <div className={classes.root}>
        <YoutubePlayer onEnd={onClose}/>
      </div>
    </Dialog>
  )
}

export default HelpMovieModal
