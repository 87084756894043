import { AppBar, Avatar, Badge, Box, ButtonBase, IconButton, LinearProgress, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import type { AppBarProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import { getInitials } from 'src/utils/get-initials'
import { Bell as BellIcon } from '../../icons/bell'
import { Menu as MenuIcon } from '../../icons/menu'
import { AccountPopover } from './account-popover'
import { Logo } from '../logo'
import NextLink from 'next/link'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpMovieModal from './help-movie-modal'
import { ProgressConsumer } from 'src/contexts/progress-context'
import { useRouter } from 'next/router'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { getTenant } from 'src/lib/tenant'
import { zIndexConstant } from 'src/lib/z-index-helper'
import UAParser from 'ua-parser-js'
import { useSession } from 'next-auth/react'

interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void
  showForms: boolean
  setShowForms: (showForms:boolean) => void
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none',
      }),
}))

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const { data: session } = useSession()

  const handleOpenPopover = (): void => {
    setOpenPopover(true)
  }

  const handleClosePopover = (): void => {
    setOpenPopover(false)
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        className={"tour-3"}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2,
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
          }}
          // src={user.avatar}
        >
          {getInitials(session?.user?.name || '')}
          {/* <UserCircleIcon fontSize="small" /> */}
        </Avatar>
      </Box>
      <AccountPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover} />
    </>
  )
}

interface HelpButtonProps {
  setIsHelpModalOpen: Dispatch<SetStateAction<boolean>>
}

const HelpButton:FC<HelpButtonProps> = (props) => {
  const { setIsHelpModalOpen } = props
  return (
    <>
      <IconButton href='/docs' target={'_blank'}>
        <HelpOutlineIcon />
      </IconButton>
    </>
  )
}

export const LogoSwitcher = () => {
  const tenantName = getTenant()
  if(tenantName == "classroomaid"){
    return <Stack direction="row" spacing={1} sx={{alignItems:"center"}}>
      <img src='/classroomaid-favicon-192x192.png' height={31} />
      <span style={{fontSize:"1.375rem", fontWeight:400, lineHeight:"1.75rem", color:"#5f6368"}}>ClassroomAID</span>
    </Stack>
  } else if(tenantName == "zettalinx"){
    return <img src='/my-static/footer_logo_Z.png' height={60}></img>
  } else {
    return <Logo
      sx={{
        height: 40,
        width: 40
      }}
    />
  }
}

export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onOpenSidebar, showForms, setShowForms, ...other } = props
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false)
  const [showGoogleLens, setShowGoogleLens] = useState<boolean>(false)
  const tenantName = getTenant()
  const router = useRouter(); 
  
  const onShowFormsClick = () => {
    setShowForms(!showForms)
  }

  const dashboardNavbarRootSx = () => {
    let sx
    if(tenantName == "classroomaid"){
      sx = {
        width: {
          lg: 'calc(100%)',
        },
        zIndex:zIndexConstant.dashboardNavBar,
      }
    } else if(tenantName == "zettalinx"){
      sx = {
        width: {
          lg: 'calc(100%)',
        },
        backgroundColor: "#1eaa39",
        zIndex:zIndexConstant.dashboardNavBar,
      }
    } else {
      sx = {
        width: {
          lg: 'calc(100%)',
        },
        zIndex:zIndexConstant.dashboardNavBar,
      }
    }
    return sx
  }

  useEffect(() => {
    const uaParserResult = UAParser(window.navigator.userAgent);
    if(uaParserResult.browser.name == "Chrome"){
      setShowGoogleLens(true)
    }
  }, []);

  return (
    <>
      <DashboardNavbarRoot
        sx={dashboardNavbarRootSx()}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <NextLink legacyBehavior href="/teacher/prints" passHref>
            <a className='tour-8' style={{textDecoration:"none"}}>
              <LogoSwitcher />
            </a>
          </NextLink>
          <Box sx={{ flexGrow: 1 }} />
          <HelpButton setIsHelpModalOpen={setIsHelpModalOpen}/>
          <IconButton aria-label="showSidebar" onClick={onShowFormsClick}>
            <ViewSidebarIcon color={ showForms ? "primary" : "disabled"}/>
          </IconButton>
          <AccountButton />
        </Toolbar>
        <ProgressConsumer>
          {({ progress }) => (
            <Box sx={{ width: '100%' }}>
              {progress.showProgress && <LinearProgress />}
            </Box>
          )}
        </ProgressConsumer>
      </DashboardNavbarRoot>
      <HelpMovieModal
        onClose={()=>{ setIsHelpModalOpen(false) }}
        open={isHelpModalOpen}
      />
    </>
  )
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}
