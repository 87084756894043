import LogoutIcon from '@mui/icons-material/Logout'
import { Avatar, Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { FC, useEffect } from 'react'
import toast from 'react-hot-toast'
import { getInitials } from 'src/utils/get-initials'
import { Cog as CogIcon } from '../../icons/cog'
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { usePrinthubTour } from '../onboarding/printhub-tour-provider'
import { inquiryLink } from 'src/lib/inquiry-link'
import { signOut, useSession } from 'next-auth/react'

interface AccountPopoverProps {
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props
  const router = useRouter()
  const { data: session } = useSession()
  const { showStep5 } = usePrinthubTour()

  const handleLogout = async (): Promise<void> => {
    try {
      onClose?.()
      await signOut({ redirect: false })
      router.push('/')
    } catch (err) {
      console.error(err)
      toast.error('Unable to logout.')
    }
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        showStep5()
      }, 1000)        
    }
  }, [open])

  const teacherSection = () => {
    const isTeacher = router.pathname.includes('teacher')
    return isTeacher && <>
      <NextLink legacyBehavior href="/account" passHref>
        <MenuItem>
          <ListItemIcon>
            <CogIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className='tour-4' primary={<Typography variant="body1">設定</Typography>} />
        </MenuItem>
      </NextLink>
      <NextLink href={inquiryLink()} passHref>
        <MenuItem>
          <ListItemIcon>
            <HelpCenterIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography color={"#df503e"} variant="body1">お問い合わせ</Typography>} />
        </MenuItem>
      </NextLink>
      <Divider />
    </>
  }


  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        <Avatar
          // src={user.avatar}
          sx={{
            height: 40,
            width: 40,
          }}
        >
          {getInitials(session?.user?.name || '')}
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">{session?.user?.name}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        {teacherSection()}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">ログアウト</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  )
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}
