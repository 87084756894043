import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

interface YouTubePlayerProps {
  onEnd?: () => void
}

const pageToVideoId = {
  "teacher/prints" : "U02DRjnmGk8",
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = (props) => {
  const { onEnd } = props
  const opts: YouTubeProps['opts'] = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const path = window.location.pathname
  const videoId = pageToVideoId[path]
  if(videoId){
    return <YouTube videoId={videoId} opts={opts} onEnd={onEnd} />;
  } else {
    return <YouTube videoId="U02DRjnmGk8" opts={opts} onEnd={onEnd} />;
  }
}
export default YouTubePlayer